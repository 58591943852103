import { OrganisationUnit } from "../../services/organisation_units.service";

export default class OrganisationUnitsSelectorCtrl {
  private organisationUnits: OrganisationUnit[];
  private getSalesUnits: boolean;

  constructor () {}

  public $onInit () {
    if (this.getSalesUnits) {
      OrganisationUnit.salesUnits().then((organisationUnits) => {
        this.organisationUnits = organisationUnits;
      });
    } else {
      OrganisationUnit.getAll().then( (organisationUnits) => {
        this.organisationUnits = organisationUnits;
      });
    }
  };
}
